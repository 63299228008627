


.flexible {
    li:first-child .flex-titre {
        // margin-top: 0!important;
    }
    li:last-child .flex {
        // margin-bottom: 0;
    }
    .banner-content {
        padding:0;
        max-width: none;
    }
}



.flex {
    display: inline-block;
    position: relative;
    width:100%;
    .sur-titre {
        font-size: 10px;
        font-weight: $bold;
        color:$touche1;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        line-height: 1.25;
        margin-bottom: 20px;
    }
    .titre {
        font-size: 22.5px;
        line-height: 1;
        font-weight: $bold;
        letter-spacing: 0px;
        color:$touche2;
        @include brk ($tp) { font-size: 21px; }
        @include brk ($m) { font-size: 20px; }
    }
    .chapeau p {
        font-size: 15px;
        font-weight: $bold;
        // font-style: italic;
        font-family: $family1;
        line-height: 1.5;
        color:#625538;
        margin-bottom: 20px;
        position: relative;
        clear: both;
        // padding-left:20px;
        // &:before {
        //     content:"";
        //     display: inline-block;
        //     background:#625538;
        //     width:14px;
        //     height:1px;
        //     position: absolute;
        //     left:0px;
        //     top:20px;
        // }
        @include brk($m) {
            font-size: 18px;
            padding-left:0px;
            margin-bottom: 10px;
            &:before {display:none}
        }

    }
    .lire-la-suite {
        margin-top: 20px;
        color:#fff;
        border-radius: 10px;
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 2.5px;
        font-weight: $bold;
        padding:20px 30px;
    }
}








.flex-titre-chapeau {
    margin-top: 0px;    
    margin-bottom: 25px;       
    .titre {
        font-size: 35px;
        font-weight: $thin;
        line-height: 1.2;
        color:$touche2;
        padding-bottom:25px;
        // border-bottom: 1px solid transparentize(#000, 0.9);
        margin-bottom: 5px;
        @include brk($t) {
            font-size: 30px;
        }
        @include brk($m) {
            font-size: 21px;
        }
    }
    .chapeau p {
        font-size: 15px;
        font-weight: $bold;
        line-height: 2;
    }
}



.flex-titre-chapeau-texte {
    margin-top: 0px;    
    margin-bottom: 70px;       
    .titre {
        font-size: 35px;
        font-weight: $thin;
        line-height: 1.2;
        color:$touche2;
        padding-bottom:25px;
        // border-bottom: 1px solid transparentize(#000, 0.9);
        margin-bottom: 5px;
        @include brk($t) {
            font-size: 30px;
        }
        @include brk($m) {
            font-size: 21px;
        }
    }
    .chapeau p {
        font-size: 15px;
        font-weight: $bold;
        line-height: 2;
    }
}



// TEXTE | TEXTE --------------------------------

.flex-texte_texte {
    margin-bottom: 50px;
    @include brk($td) {
        margin-bottom: 10px;
    }
    @include brk($m) {
        margin-bottom: 0px;
    }
    .col {
        width:42%;
        &.right {
            float: right;
        }
        @include brk($td) {
            width:44%;
        }
        @include brk($m) {
            width:100%;
            margin-bottom: 45px;
        }
    }
    .titre-container {
        border-bottom:1px solid transparentize(#000, 0.9);
        margin-bottom: 16px;
        position: relative;
        .icon-container {
            position: absolute;
            top:-5px;
            left:0;
            width:28px;
            height:28px;
            display: inline-block;
            &.problem {
                transform: translate3d(0,1px,0);
                svg {
                    transform: scale(0.9);
                }
            }
        }
        .titre {
            padding-left:40px;
            font-size: 21px;
            font-weight: $light;
            padding-bottom: 25px;
            letter-spacing: 0;
        }
    }
    .chapeau {
        p, a {
            line-height: 1.8;
            font-size: 15px;
            font-weight: $normal;
        }
    }
}











// IMAGE  --------------------------------

.flex-image {
    height:337px;
    margin-bottom: 27px;
    @include brk ($t) { height:290px; }
    @include brk ($m) { height:250px; }
    &.has-lightbox .image {
        cursor:pointer;
    }
}






// IMAGE | TEXTE,  TEXTE | IMAGE --------------------------------

.flex-image_texte, .flex-texte_image, {
    margin-bottom: 45px;
    @include brk ($t) {
        margin-bottom:40px;
    }
    .content {
        // padding-top:80px;
        // padding-bottom: 80px;
    }
    .col-image {
        height:auto;
        width:37%;
        overflow: hidden;
        margin-top: 0px;
        &.contains-img {
            @include brk ($m) {
                height:auto;
            }
        }
        .image {
            // border-radius:6px;
            animation: fade 2s;
        }
        >img {
            width:100%;
            height:auto;
            // border-radius:8px;
            @include brk ($t) {
                // width:auto;
                // height:100%;
            }
            @include brk ($tp) {
                width:auto;
                height:100%;
            }
            @include brk ($m) {
                width:100%;
                height:auto;
            }
        }
        .image-lightbox {
            img {
                width:100%;
                visibility: hidden;
                height:auto;
            }
        }
        .bandeau {
            background:$touche1;
            color:#fff;
            z-index: 10;
            position: absolute;
            top:86px;
            right:calc(-75% + 100px);
            width:150%;
            text-align: center;
            padding: 14px 0;
            font-size: 10px;
            text-transform: uppercase;
            font-weight: $bold;
            letter-spacing: 3px;
            transform: rotate(45deg);
            @include brk ($m) {
                top:75px;
            }
        }
    }
    .col-texte {
        width:56%;
        .titre-container {
            margin-top: 2px;
            margin-bottom: 10px;
            .trait {
                content:"";
                // position: absolute;
                // top:10px;
                // left:calc(100% + 20px);
                height:1px;
                margin-left: 20px;
                width:72px;
                opacity: 1;
                transform: translate3d(0,-7px,0);
                transition: width 0.1s, opacity 0.5s;
                display: inline-block;
                @include brk($t) {
                    // display:none;
                }
            }
            .titre {
                position: relative;
                display: inline-block;
            }
        }
        img.icone {
            width:71px;
            height:auto;
            float: left;
            margin-right: 20px;
            transform: translate3d(0,-23px,0);
        }
        ul.liste {
            width:100%;
            li {
                padding: 17px 0;
                margin:0;
                border-bottom: 1px solid transparentize(#625538, 0.85);
                &:before {display:none;}
                .caracteristique, .valeur {
                    display: inline;
                    padding:0;
                }
                .caracteristique {
                    font-weight: $bold;
                }
            }
        }
        .btn { 
            background:$touche1;
            border-radius:30px;
            height: 40px;
            position: relative;
            p {
                padding:6px 77px 16px 26px;
                font-size: 14px;
                font-weight: $bold;
                letter-spacing: 0;
                color:$white;
                text-shadow: 0px 4px 0px rgba(95, 107, 120, 0.34)!important;
            }
            .icon {
                font-size: 38px;
                color:#fff;
                width:47px;
                text-align: center;
                font-weight: $normal;
                height:100%;
                position: absolute;
                top:3px;
                line-height: 1;
                right:6px;
                border:none;
                &::before {
                    content:"";
                    height:25px;
                    top:4px;
                    width:1px;
                    background:transparentize(#fff, 0.8);
                    position: absolute;
                    display: inline-block;
                    left:0px;
                }
                span {
                    transition: transform 0.5s;
                    display: inline-block;
                    height:100%;
                    line-height: 1;
                    color:$white;
                    transform: translateY(-5px);
                    text-shadow: 2px 2px 0px rgba(95, 107, 120, 0.34);
                }
            }
        }
    }
    
    @include brk($tp) {
        .col-image, .col-texte {
            width:100%;
            float: none!important;
        }
        .col-image {
            height:275px;
            margin-bottom:40px;
            .image-lightbox img {
                margin: 0 auto;
                display: block;
                width:60%; 
            }
        }
    }
    @include brk($m) { 
        margin-bottom:55px;
        .col-image {
            height:auto;
            margin-bottom:40px;
            .image {
                width:130%;
                left:-15%;
            }
        }
        ul.caracteristiques li {
            padding: 12px 0 !important;
            &:last-child {
                border-bottom:none;
            }
        }
    }
}

.flex-image_texte {
    .col-texte { float: right; }
}
.flex-texte_image {
    .col-image { float: right; }
}










// FLEX TELECHARGEMENTS  -----------------------------------------


.flex-telechargements {
    background:#f9f9f9;
    position: relative;
    padding: 40px 0px 30px;
    margin-top: 35px;
    margin-bottom:50px;
    box-shadow: 0px 5px 43px 0px rgba(0, 0, 0, 0.2);
    @include brk($m) {
        padding: 30px 0px 20px;
        width: 114%;
        margin-left: -7%;
    }
    .titre-section {
        font-size: 11px;
        font-weight: $bold;
        color:$touche1;
        text-transform: uppercase;
        letter-spacing: 2px;
        padding-left:47px;
        padding-bottom:18px;
        user-select:none;
        @include brk($m) {
            padding-left:6%;
        }
    }
    ul {
        li {
            display: inline-block;
            width:100%;
            margin-bottom: 0;
            &:first-child a .nom-fichier {border-top:none;}
            a {
                display: inline-block;
                padding: 0px 100px 0px 82px;
                width:100%;
                position: relative;
                @include brk($t) {
                    padding: 0px 100px 0px 82px;
                }
                @include brk($m) {
                    padding: 0px 10% 0px calc(6% + 28px);
                }
            }
            .nom-fichier, .type {
                font-size: 15.5px;
                display: inline;
                color:#424242;
                font-weight: $light; 
                // text-transform: uppercase;
                letter-spacing: 0.2px;
                line-height: 1.1;
                padding-top:18px;
                letter-spacing: 0.8px;
                @include brk($m) {
                    font-size: 11.5px;
                }
            }
            .nom-fichier {
                padding:17px 0 17px;
                transition: border 0.5s;
                width:100%;
                display: inline-block;
                border-top:1px solid transparentize(#000, 0.93);
                .type {
                    font-size: 9px;
                    letter-spacing: 0.5px;
                    line-height: 2.1;
                    margin-left: 0px;
                }
            }
            .icon {
                width:12px;
                height:12px;
                position: absolute;
                left:47px;
                top:17px;
                display:inline-block;
                path, rect {fill:$touche1; transition: all 0.3s;}
                path {transform: translate3d(0,0,0);}
                @include brk($m) {
                    left:6%;
                }
            }
            &:hover {
                a {
                    background:#fff;
                    // path, rect {fill:rgba(0,0,0,0.9)}
                    path {transform: translate3d(0,3px,0);}
                }
                & .nom-fichier, + li .nom-fichier {
                    transition: border 0s;
                    border-top:1px solid transparentize(#000, 1);
                }
            }
        }
    }
}










// APPEL A ACTION ----------------------------------------------



.flex-appel {
    // background:#2e2b26;
    margin-bottom: 50px;
    .image-fond {
        background-image:url('../assets/images/fond-appel.png');
        background-position: 50% 50%;
        background-size:cover;
    }
    .banner-content {
        padding-top: 00px;
        padding-bottom: 0px;
        @include brk($t) { padding-top: 50px; }
        @include brk($m) { padding-top: 0px; }
        .content {
            padding-top: 40px;
            border-top:1px solid transparentize(#000, 0.9);
            border-bottom:1px solid transparentize(#000, 0.9);
        }
    }
    .sur-titre {
        font-size: 22.5px;
        font-family: $family2;
        font-style: italic;
        color:#e0ceb1!important;
        position: relative;
        margin-bottom: 15px;
        font-weight: $normal;
        @include brk($m) { font-size: 17px; }
        &::before, &::after {
            content:"";
            height:1px;
            width:15px;
            background:#e0ceb1;
            font-size: 64px;
            display: inline-block;
            margin: 0 12px;
            transform: translate3d(0,-7px,0);
            @include brk($t) {
                background:#968d78;
                transform: translate3d(0,-6px,0);
            }
            @include brk($m) {
                transform: translate3d(0,-4px,0);
            }
        }
    }
    .titres-container {
        width: 65%;
        float: left;
        margin-bottom: 50px;
        @include brk($m) { 
            margin-bottom: 35px;
            width: 100%;
        }
        .titre {
            font-size: 20px;
            color:$touche2;
            position: relative;
            line-height: 1.5;
            letter-spacing: 0.5px;
            padding-bottom:0;
            @include brk($t) { font-size: 19px; }
            @include brk($m) { 
                font-size: 18px; 
                // margin-bottom: 35px;
                span {display:block;}
            }
            &.titre-2 {
                font-weight: $light;
            }
        }
    }
    .btn { 
        position: relative;
        // border:1px solid transparentize($touche1, 0.5);
        border-radius:20px;
        height:60px;
        float: right;
        @include brk($m) { 
            float: left;
            margin-bottom: 45px;
        }
        p {
            font-size: 15.5px;
            // text-transform: uppercase;
            font-weight: $bold;
            letter-spacing: 1px;
            line-height: 1;
            color:#fff;
            position: relative;
            display:inline-block;
            padding-top:22px;
        }
        .icon {
            top:13px;
        }
        .icon-download {
            width:14px;
            height:14px;
            margin-left: 23px;
            margin-right: -15px;
            transform: translate3d(0,5px,0);
            // position: absolute;
            // left:0;
            // top:23px;
            display:inline-block;
            path, rect {fill:#fff; transition: transform 0.3s;}
            path {transform: translate3d(0,0,0);}
        }

        &:hover {
            background:$touche2 !important;
            p {
                // color:#2e2b26;
            }
            path, rect {fill:#2e2b26}
            path {transform: translate3d(0,2px,0);}
        }
    }
}











.flex-cards {
    margin-bottom: 100px;
    @include brk($td) {
        margin-bottom: 80px;
    }
    @include brk($m) {
        margin-bottom: 50px;
    }
    .content>ul {
        // display: inline-flex; /* or inline-flex */
        // justify-content: space-between;
        // flex-direction: column;
        >li {
            width:49%;
            min-height:410px;
            float: left;
            box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
            padding:45px 30px 45px 40px;
            margin-bottom: 20px;
            &:nth-child(2n){float:right}
            @include brk($td) {
                padding:45px 30px 35px 40px;
                min-height:430px;
            }
            @include brk($m) {
                padding:30px 25px 30px 25px;
                width:100%;
                min-height:300px;
            }
            .icon-container {
                width:46px;
                height:46px;
                margin-bottom: 40px;
                background-size: contain;
                @include brk($m) {
                    margin-bottom: 35px;
                }
            }
            .titre {
                font-size: 22.5px;
                font-weight: $light;
                margin-bottom: 10px;
            }
            .texte {
                li {
                    line-height: 1.2;
                }
            }
        }
    }
}


