


.archive {
	background:#ffffff;
}





// BANNER TOP

$page_wrapper-bannerTop-desktop: 475px;
$page_wrapper-bannerTop-laptop: 460px;
$page_wrapper-bannerTop-tablet: 482px;
$page_wrapper-bannerTop-mobile: 236px;



.archive .banner.top {
    background:#212121;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.archive .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}

.archive.references {
	.banner.top {
	    background:none;
	    height:690px;
	    @include brk($l) { height:690px }
	    @include brk($t) { height:500px }
    	@include brk($m) { height:300px }
    	.masque {
    		position: absolute;
    		width:40%;
    		height:100%;
    		top:0;
    		left:0;
    		background-size:cover;
    		background-position: 100% 50%;
    		@include brk($t) {
	        	display: none;
	        }
    	}
	    .gradient {
	        position: absolute;
	        height:270px;
	        top:auto;
	        bottom:0;
	        left:0;
	        right:0;
	        z-index: 90;
	        background: linear-gradient(to bottom, 
	            transparentize(#fff, 1), 
	            transparentize(#fff, 0.4) 30%, 
	            transparentize(#fff, 0.3) 50%, 
	            transparentize(#fff, 0.1) 80%, 
	            transparentize(#fff, 0)
	        );
	        @include brk($t) {
	        	height:100px;
	        }
	        @include brk($td) {
	        	height:100px;
	        }
	        @include brk($tp) {
	        	height:80px;
	        }
	    }
	}
	.banner.medium {
		z-index: 100;
		margin-top: -400px;
		background:none;
		display: block;
		@include brk($l) { margin-top:-370px }
		@include brk($t) {
			padding-top:30px;
			margin-top: 0px;
		}
		@include brk($td) {
			padding:30px 0 90px;
		}
		@include brk($tp) {
			padding:25px 0 50px;
		}
		.content {
			min-height: 400px;
		}
		.diagonal-gradient {
			position: absolute;
	        height:900px;
	        width: 60%;
	        transform: rotate(45deg);
	        top:-700px;		   
	        left:-150px;
	        right:0;
	        z-index: 80;
	        background: linear-gradient(to bottom, 
	            transparentize(#fff, 1), 
	            transparentize(#fff, 0) 20%, 
	            transparentize(#fff, 0) 30%, 
	            transparentize(#fff, 0) 50%, 
	            transparentize(#fff, 0) 80%, 
	            transparentize(#fff, 0)
	        );
	        display: none;
	        @include brk($td) {
	        }
	        // background:green;
		}
		ul {
			min-height: 200px;
			display: none;
			&.visible {
				display: block;
			}
		}
	}
}




.archive .banner.top {
	// padding-top:50px;
	// animation: fade 1s;
	transition:height 1s;
	.banner-content {
		@include brk($t) {
			padding:0;
		}
	}
	.content {
		height:100%;
		position: relative;
	}
	#google_map {
        animation: fade 2.5s;
        height:100%;
        width:100%;
        // height:390px;
        position: absolute!important;
        /*======= Map Styling ============*/
        .gmnoprint a, .gmnoprint span {
            display:none;
        }
    }
}






.archive .banner.top .focus-container {
	background: #fff;
	width:50%;
	position: absolute;
	bottom:0px;
	right:0;
	height:310px;
	z-index: 100;
	background:darken(#fff, 1%);
	box-shadow: 0px 5px 39px 0px rgba(0, 0, 0, 0.08);
	@include brk($td) {
		width:65%;
	}
	@include brk($m) {
		width:90%;
		height:199px;
		bottom:-40px;
	}

	.inside {
		padding:40px 70px;
		@include brk($m) {
			padding:30px 30px;
		}
		.focus, .date {
			font-size: 9.5px;
			font-weight: $semi-bold;
			color:$touche1;
			animation: fade 1s;
			text-transform: uppercase;
			letter-spacing: 1.5px;
			margin-bottom: 40px;
			@include brk($m) {
				margin-bottom: 12px;
			}
		}
		.titre {
			font-size: 25px;
			font-weight: $bold;
			color:$touche2;
			animation: fade 1s;
			// text-transform: uppercase; 
			line-height: 1.6;
			position: relative;
			&:before {
	            content:"";
	            display: inline-block;
	            background:$touche1;
	            width:20px;
	            height:1px;
	            position: absolute;
	            left:-40px;
	            top:22px;
	            @include brk($m) {
					display: none;
				}
	        }
			@include brk($m) {
				font-size: 17.5px;
			}
		}
		.lire-la-suite {
			position: absolute;
			bottom:45px;
			left:70px;

			@include brk($m) {
				left:30px;
				bottom:25px;
			}
			p {
				animation: fade 1s;
				color:$touche2;
				font-size:13px;
				font-weight: $bold;
			}
			&::after {
				content:"";
				display: inline-block;
				height:4px;
				width:0;
				background:$touche1;
				position: absolute;
				bottom:-16px;
				left:0;
				opacity: 0;
				transition: width 0.1s, opacity 0.8s;
			}
		}
		&::after {
			content:"";
			display: inline-block;
			width:1000%;
			height:100%;
			position: absolute;
			left:100%;
			top:0;
			background:$touche1;
			box-shadow: 0px 5px 39px 0px rgba(0, 0, 0, 0.08);
		}
	}
	&:hover {
		background:darken(#fff, 0%);
		.lire-la-suite {
			&::after {
				opacity: 1;
				width:93%;
			}
		}
	}
	
}











.archive .banner.medium {
	margin-top:-5px;
	background:#ffffff;
	padding:75px 0 120px;
	// margin-top:-5px;
	@include brk($t) {
		padding:80px 0 90px;
	}
	@include brk($m) {
		padding:85px 0 50px;
	}
	&.news {
		background: #fbfbfb;
	}
	.image-background-news {
        background-size: cover;
        background-position:50% 30%;
        position: absolute;
        bottom:0;
        left:0;
        right:0;
        height: 650px;
        width: 100%;
        animation: fade 1s;
        @include brk($m) {
            display: none;
        }
    }
	.content {
		position: relative;
	}
    .sur-titre {
	    font-size: 9.5px;
	    font-weight: $semi-bold;
	    font-family: $family1;
	    animation: fade 1s;
	    color: transparentize($touche1, 0);
	    text-transform: uppercase;
	    letter-spacing: 1.5px;
	    line-height: 2;
	    padding: 0 0%;
	    user-select:none;
	    display: inline-block;
	    margin-bottom: 25px;
	    transform: translate3d(0,0,0); 
	    z-index: 100;
	    @include brk($tp) {
            margin-bottom: 20px;
    	}
	    // TRAIT ------------------------
        position: relative;
        &::after {
            content:"";
            position: absolute;
            top:8px;
            left:calc(100% + 20px);
            height:1px;
            width:70px;
            opacity: 1;
            transition: width 0.1s, opacity 0.5s;
            display: inline-block;
            background: transparentize($touche1, 0.6);
            @include brk($o) {
                display:none;
            }
        }
    }
	.titre-type {
		font-size: 32.5px;
        font-weight: $bold;
        font-family: $family1;
        color: $touche2;
        line-height: 1.2;
        // text-transform: uppercase;
        letter-spacing: 0px;
        margin-bottom: 45px;
        position: relative;
        z-index: 100;
        span {
        	font-weight: $bold;
        }
        &.references {
        	font-size: 52.5px;
        	@include brk($td) {
	            font-size: 40px !important;
	    	}
        	@include brk($tp) {
	            font-size: 32px !important;
	    	}
	    	@include brk($tp) {
	            font-size: 27.5px !important;
	    	}
        }
        @include brk($m) {
            font-size: 27.5px !important;
    	}
	}
}







.banner.list {
    background:darken(#fbfafa, 2%);
    padding:90px 0 90px;
    z-index:80;
	@include brk($t) { padding:60px 6% 60px; }
	@include brk($t) { padding:60px 0% 60px; }
	@include brk($m) { padding:50px 0% 60px; }
    .content {
        // position: relative;
    }
    .titre-section-container {
        margin-bottom: 47px;
        position: relative;
        @include brk($m) {
        	margin-bottom: 10px;
        }
        .sur-titre {
            font-size: 11px;
            font-weight: $normal;
            color: $touche1;
            text-transform: uppercase;
            letter-spacing: 5px;
            margin-bottom: 10px;
        }
        .titre-type {
            font-size: 27.5px;
	        font-weight: $bold;
	        color: $touche2;
	        letter-spacing: 0.5px;
	        margin-bottom: 45px;
	        @include brk($l + 70px) {
				padding-left: 80px;
            }
	        @include brk($m) {
	        	margin-bottom: 25px;
	        	padding-left: 0px;
	            font-size: 25px;
	        }

	        
	        // TRAIT ------------------------
	        position: relative;
	        &::after {
	            content:"";
	            position: absolute;
	            top:22px;
	            left:-83px;
	            height:1px;
	            width:54px;
	            opacity: 1;
	            transition: width 0.1s, opacity 0.5s;
	            display: inline-block;
	            background: transparentize($touche1, 0.6);
	            @include brk($l + 70px) {
					left:0;
	            }
	            @include brk($m) {
	                display:none;
	            }
	        }
        }
    }
}
















// LISTE TYPE 2 ----------------------------------------------


$li_height: 98px;


.archive.references .banner.medium {
	.infos-container {
		width: 100px !important;
		display: none!important;
	}
}


.banner.list.home {
	.infos-container {
		padding-left: 45px!important;
		width: 153px !important;
		@include brk($tp) {
			padding-left: 0px!important;
		}
	}
	.image-container {
		// display: none !important;
	}
	.textes-container {
		// width:100%;
	}
}


.archive .banner.medium,
.banner.list {
    ul {
    	position: relative;
        z-index: 100;
		li {
			height:$li_height;
			width:100%;
			background:darken(#fff, 1%);
			margin-bottom: 1px;
			border:1px solid transparentize(#000, 0.95);
			margin-top: -1px;
			box-shadow: 0px 5px 30px 0px rgba(0, 0, 0, 0.03);
			@include brk($t) {
				width:100%;
				float: none!important;
			}
			@include brk($td) {
				background:transparentize(#fff, 0);
			}
			@include brk($m) {
				height:auto;
				// margin-bottom: 27px;
				// background:transparentize(#fff, 1);
			}
			&.not-clickable a {
				cursor: default;
			}
			.image-container {
				width:194px;
				position: relative;
				height:100%;
				float: left;
				display: inline-block;
				// transition: filter 0.3s;
				filter: brightness(98%);
				@include brk($td) {
					filter: brightness(100%);
				}
				@include brk($m) {
					height:110px;
					width:100%;
					float: none!important;
				}
				.image {
					animation: none;
					background-size: cover;
				}
			}
			.textes-container {
				width:calc(100% - 194px);
				height:$li_height;
				float: right;
				position: relative;
				@include brk($t) {
					float: none;
					display: inline-block;
				}
				@include brk($tp) {
				}
				@include brk($m) {
					padding: 14px 25px 24px;
					height:auto;
					width:100%;
					float: none!important;
					padding: 14px 25px 24px;
				}
				.infos-container {
					height:$li_height;
					line-height: $li_height;
					display: inline-block;
					padding-left:35px;
					width: 136px;
					height:$li_height;
					line-height: $li_height;
					@include brk($tp) {
					}
					@include brk($m) {
						width: 100%;
						height:auto;
						line-height: 1;
						padding-left:0px;
					}
					.inside {
						height:auto;
						@extend .bloc;
						border-right: 1px solid transparentize(#000, 0.9);
						@include brk($tp) {
							border-right: none;
						}
						p {
							font-size: 13px;
							font-weight: $normal;
							display: inline-block;
							color:$touche1;
							letter-spacing: 0px;
							text-transform: uppercase;
							padding: 16px 0px;
							@include brk($tp) {
								font-size: 13px;
								padding: 10px 0px 13px;
							}
							@include brk($m) {
								font-size: 13px;
								border-right: none;
							}
							span {
								margin-left: 2px;
								margin-right: 7px;
							}
						}
					}
				}
				.titre-container {
					height:$li_height;
					line-height: $li_height;
					display: inline-block;
					// width:calc(100% - 236px);
					@include brk($tp) {
					}
					@include brk($m) {
						width: 100%;
						height:auto;
						line-height: 1;
					}
					.titre-post {
						font-size: 14px;
						font-weight: $normal;
						color:#424242;
						padding: 0px 35px 0 35px;
						line-height: 1.35!important;
						@extend .bloc;
						@include brk($td) {
							font-size: 14px;
							line-height: 1.30!important;
						}
						@include brk($tp) {
							font-size: 14px;
						}
						@include brk($m) {
							padding: 0px 0px;
							
						}
					}
				}
				.lire-la-suite {
					height:56px;
					width: 56px;
					border-radius:50%;
					color:#fff;
					text-align: center;
					font-size:40px;
					font-weight: $normal;
					position: absolute;
					top:23px;
					right:23px;
					background: #ebebeb;
					.icon {
				        width:100%;
				        height:100%;
				        transition: transform 0.5s, background 0s;
				        transform: rotate(-90deg);
				        svg {
				            width:20px;
				            height:20px;
				            display: inline-block;
				            margin-top: 0px;
				            margin-right: 0px;
				            // transform: translate3d(5px,0,0);
				            .st0{fill:#FFFFFF;}
				        }
				    }
				    @include brk($t) {
						display: none;
					}
				}
			}
			&:not(.not-clickable):hover {
				.image-container {
					filter: brightness(100%);
				}
				.lire-la-suite {
					background:#b3b3b3
				}
			}
			@include brk($d) {
				&:not(.not-clickable):hover {
					background:#fff;
				}
			}
		}
	}
}

