



#header_behind {
    height:$header-height;
    background: #fff;
    position: relative;
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
    &.page {
        background: #fff;
    }
}


#header {
    height:$header-height;
    position:fixed;
    top:0px;
    left:0px; 
    z-index: 99;
    background:linear-gradient(to bottom, 
        #ffffff 0%,
        // transparentize(#fff, 0.4) 50%,
        transparentize(#fff, 1) 100%
    );
    background:$white;
    border-bottom: 1px solid rgba(255,255,255,0.15);
    box-shadow: 0px 3px 40px 0px rgba(0, 0, 0, 0.35);
    @include brk($t) {height:$header-height-tablet;}
    @include brk($m) {height:$header-height-mobile;}
    &.page {
        background:none;
    }
    .background {
        position: absolute;
        left:0;
        top:0;
        width:100%;
        display: inline-block;
        height:$header-height;
        @include brk($t) {height:$header-height-tablet;}
        @include brk($m) {height:$header-height-mobile;}
    }
    .banner-content {
        height: $header-height;
        margin: 0 auto;
        @include brk($menu-large) {
            margin:0; 
            padding:0 40px;
            transition: 0.5s;
        }
        @include brk($t) {
            height:$header-height-tablet;
            padding:0 6%;
        }
        @include brk($m) {height:$header-height-mobile;}
    }
}







#header .logo-container {
    float: left;
    width: 140px;
    height: $header-height;
    position: relative;
    transition: box-shadow 0.3s;
    display: inline-block;
    // box-shadow: 0px 5px 43px 0px rgba(0, 0, 0, 0.2);
    &:hover {
        // box-shadow: 0px 5px 43px 0px rgba(0, 0, 0, 0.26);
    }
    @include brk($t) {
        height: $header-height-tablet;
        width: 131px;
    }
    @include brk($m) {
        height: $header-height-mobile;
        width: 102px;
        margin-left: -10px;
    }
    .logo-image {
        height: 100%;
        width: 100%;
        // background: url('../images/logo.png');
        // background: url('../images/logo.svg');
        // background-size:contain;
        display: inline-block;
        position: absolute;
        top:0px;
        left:0px;
        .st0{fill:#B1B0AF;}
        .st1{fill:#0098FF;}
        .st2{fill:#3D3D3B;}
        @include brk($t) {
        }
        @include brk($m) {
        }
    }
}



#header .gmbh {
    float: left;
    display: inline-block;
    color:#575656;
    margin-left: 22px;
    margin-top: 49px;
    font-size: 11.6px;
    line-height: 1;
    font-weight: $light;
    border-left:1px solid transparentize(#000, 0.9);
    padding: 10px 17px 10px;
    cursor: default;
    user-select:none;
    @include brk($t) {
        padding: 10px 17px 10px;
        margin-left: 20px;
        margin-top: 19px;
    }
    @include brk($m) {
        display: none;
    }
}



#header .btn-container {
    position: absolute;
    height: 58px;
    bottom:-58px;
    right:0;
    width:50%;
    @include brk($t){display:none;}
    .btn-01 {
        float: right;
        margin-left: 5px!important;
        box-shadow: inset 0px 33px 33px -30px rgba(0, 0, 0, 0.4);
        @include brk($t){display:none;}
        &:hover {
            background:$touche2 !important;
        }
        p {
            font-size: 15.5px;
            font-weight: $normal;
            letter-spacing: 0.5px;
            // text-transform: uppercase;
        }
        &.devis {
            // background:$touche1;
            border-bottom-left-radius:28px;
            border-bottom-right-radius:28px;
        }
    }
}














#nav_desktop {
    display:inline-block;
    height:100%;
    float:right;
    margin-right: 6px;
    @include brk($t) {display:none;}
    >ul {
        list-style-type:none;
        height:100%;
        user-select: none;
    }
    >ul>li {
        display:inline-block;
        height:$header-height;
        line-height:$header-height;
        margin-left:-5px;
        user-select: none;
        position: relative;
        &:hover, &.current-menu-item, &.current-menu-ancestor, &.current_page_ancestor, &.selected {
            >a {color:transparentize(#575656,0);}
        }
    }
    >ul>li>a {
        display: block;
        padding: 53px 11px 20px;
        font-size: 15.5px;
        letter-spacing: 0px;
        color: transparentize(#575656,0.1);
        font-weight: $light;
        // text-transform: uppercase;
        height:100%;
        user-select: none;
        &:hover {color:transparentize(#575656,0);} 
    }




    // // HOME ICON ---------------------------------

    // >ul>li:first-child {
    //     position:relative; width:57px;
    //     a::after {
    //         content:"";
    //         width:21px;
    //         height: 21px;
    //         display: inline-block;
    //         position:absolute;
    //         top:30px;
    //         left:18px;
    //         cursor:pointer!important;
    //         background:url('../assets/images/icon-home2.svg');
    //     }
    //     a {color: rgba(0,0,0,0);}
    // }



    // SOULIGNÉ ----------------------- 

    >ul>li:not(.btn)>a:before {
        content:"";
        width:0px;
        opacity: 0;
        transition: width 0.2s, opacity 0.5s, background 0.3s;
        height:5px;
        position: absolute;
        display: inline-block;
        background:$touche1;
        left:13px;
        bottom:-1px;
    }
    >ul>li:not(.btn):hover >a:before,
    >ul>li:not(.btn).current-menu-ancestor >a:before,
    >ul>li:not(.btn).current-menu-parent >a:before,
    >ul>li:not(.btn).current-menu-item >a:before,
    >ul>li.selected >a:before {
        opacity: 1;
        width:calc(100% - 25px);
    }



    // SEPARATION VERTICALE -----------------

    >ul>li{
        &::before {
            content:"";
            width:1px;
            height:16px;
            position: absolute;
            display: inline-block;
            background:transparentize(#575656, 0.9);
            top:58px;
            left:0px;
        }
        &:first-child::before {display:none;}
        // &:nth-child(2):before {display:none;}
    }



    // FLECHE PARENT -----------------

    >ul>li.menu-item-has-children {
        position: relative;
        >a {padding-right:25px;}
        >a::after {
            content:"";
            width:7px;
            height:8px;
            display: inline-block;
            position:absolute;
            top:62px;
            right:13px;
            cursor:pointer!important;
            background:url('../assets/images/parent-arrow.svg');
        }
    }


}






//  LEVEL 2   ------------------------------------


#nav_desktop >ul>li {
    &:hover ul {
        display:block;
    }
    >ul {
        position:absolute;
        left:0px;
        top:$header-height;
        margin-left: -27px;
        margin-top: 0px;
        display: none;
        z-index: 10;
        padding: 0 60px 60px 40px;
    }
    >ul>li {
        background:#fff;
        display:inline-block;
        float:left;
        line-height: 1;
        border-top: 1px solid transparentize(#000, 0.9);
        transition: border 1s;
        box-shadow: 2px 4px 14px 0px transparentize(#000, 0.91);
        &:first-child {
            border:none!important;
            a {
                // border-top: 3px solid transparentize($touche1, 0)!important;
            }
        }
        &.current-menu-item, &:hover {
            background:lighten(#000, 95%);
            &, & + li {
                transition: border 0.5s;
                border-top: 1px solid transparentize(#000, 1);
            }
        }
        &.current-menu-item>a {
            // color:$touche2;
            // font-weight: $bold;
        }
        &.blue a {color:#0025ed}
        &.green a {color:#18bcab}
        &.red a {color:#fc4831}
        &.orange a {color:#fd9808}
        >a {
            line-height: 1.45;
            font-size:15px;
            font-weight: $light;
            color: $touche3;
            width:290px;
            padding: 19px 20px;
            // text-transform: uppercase;
            letter-spacing: 0.5px;
        }
    }
    // &.current_page_ancestor>ul {display:inline-block;}
    >ul>li.menu-item-has-children.current-menu-item ul{display:block}
    >ul>li>ul {
        display: block;
        position:absolute;
        top:100px;
        left:0px;
        height:100px;
        display:none!important;
        background:$level3; width:40000px;
        >li {
            display:inline-block;
            float:left;
            height:100%;
            &:first-child a {padding-left:0}
            a {
                padding-top:42px;
                display:inline-block;
                font-size:13px;
                font-weight: 700;
                color:#333333;
                &:hover {color:$dark-grey;}
            }
        }
    }
}






#header .social-icons {
    // background:green;
    height:100%;
    float:right;
    // margin-right: -12px;
    @include brk ($tablet) {display:none;}
    .icon-container {    
        float: left;
        height:$header-height;
        line-height:$header-height;
        transition: height 1s, line-height 1s;
        position: relative;
        text-align: center;
        padding: 0 6px;
        .icon {
            width: 26px;
            height: 26px;
            @extend .bloc;
            margin-bottom: 3px;
            svg {
                transform: scale(0.8);
                transition: transform 0.5s;
            }
            .st0 {
                fill:$touche1;
                transition: fill 0.3s;
            }
            .st1 {
                fill:$touche1;
            }

        }
        &:hover {
            .icon svg { transform: scale(1);}
            .icon .st0 {fill: $touche2 !important}
            // .icon .st0 {fill: rgba(255,255,255,1)}
        }
        &.xing {
            transform: translate3d(0,1px,0);
        }
        &.facebook:hover {
            // .icon .st0 {fill: #3B5998}
        }
    }
}

















// WPML -----------------------------------------------------


header#header div.wpml-ls {
    display: none!important;
}

#header.page .wpml-ls-legacy-dropdown {
    >ul>li>a {
        color:#fff !important;
        span::after {
            background: url('../assets/images/wpml_down-white.svg')
        }
    }
}


#header .wpml-ls-legacy-dropdown {
    height: $header-height;
    position: relative; 
    display: inline-block;
    float:right;
    @include breakpoint ($menu-off) {
        display: none;
    }
    >ul {
        height:$header-height;
    }
    a {
        font-size: 14px;
        font-weight: $bold;
        color: transparentize($touche2, 0.1);
        font-family: $family1;
        text-transform: uppercase;
    }
    >ul:hover >ul>li>a {
        color: transparentize($touche2, 0);
    }
    >ul>li>a {
        height:$header-height;
        padding:  53px 16px 10px 12px;
        span {
            position: relative;
            &::after {
                content:"";
                width:8px;
                height:8px;
                position:absolute;
                top:6px;
                right:-14px;
                background-size:contain!important;
                background: url('../assets/images/wpml_down.svg')
            }
        }
    }
    ul:hover, ul:hover ul {
        visibility:visible !important;
    }
    >ul>li>ul {
        visibility:hidden;
        position:absolute;
        // height:100%;
        top:$header-height - 8px;
        left:-12px;
        z-index: 100;
        padding: 0 20px 20px;
    }
    >ul>li>ul>li {
        height: 40px; 
        width: 40px;
        background:#eeeeee;
        border-radius:50%;
        margin-bottom: 9px;
        &:hover {background:$white;}
    }
    >ul>li>ul>li>a {
        box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
        width: 100%;
        height:100%;
        text-align: center;
        padding-top:8px;
        border-radius:50%;
    }
}