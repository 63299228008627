
.lightbox-membre-all-container {
    display: none;
    &.active {
        display: block;
    }
}


$overlay_opacity: 0.75;

.page-overlay.membre {
    position: fixed;
    display: block;
    background: #000;
    opacity:$overlay_opacity;
    z-index:1999;
    &.ng-enter {
        opacity: 0;
        transition: opacity 0.3s;
        &.ng-enter-active { opacity:$overlay_opacity; }
    }
    &.ng-leave {
        opacity:$overlay_opacity;
        transition: opacity 0.35s;
        &.ng-leave-active { opacity: 0; }
    }
}


.lightbox-container.membre {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:2000;
    overflow-y: scroll;
    width: calc(101.7%);
    cursor: pointer;
    @include brk($t) {
        width: calc(100% + 25px);
    }
    @include brk($m) {
        width: calc(100% + 25px);
    }
}

$width_lightbox_personne: 950px;

.lightbox.membre {
    position: absolute;
    top:130px;
    left:calc(50% - 3px);
    margin-left: - ($width_lightbox_personne / 2);
    z-index: 1000;
    display: inline-block;
    width:100%;
    max-width:$width_lightbox_personne - 20px;
    overflow:hidden;
    transition: top 1s;
    cursor: default;
    &.ng-enter {
        transition: transform 0.5s, opacity 0.3s; 
        transform:scale(0.85); opacity:0;
    }
    &.ng-enter-active {transform:scale(1); opacity:1}
    @include brk($t) {
        top:100px;
        width:80%;
        left: 10%;
        margin-left: 0;
        margin-bottom: 90px;
    }
    @include brk($td) {
        padding-right:16px;
    }
    @include brk($m) {
        top:100px;
        width:90%;
        left:5%;
        margin-left: 0;
        padding-right:25px;
        // position: absolute;
    }
    .inside {
        background:#fff;
        display: inline-block;
        overflow: hidden;
        margin-bottom: 120px;
        // min-height:300px;
        @include brk($m) {
            margin-bottom: 75px;
        }
    }
    .image {
        display: none;
        width:407px;
        height: calc(100% + 4px);
        animation: fade 1.5s;
        @include brk($t) {
            width:300px;
        }
        @include brk(800px) {
            background-position:50% 0%;
            width:100%;
            height: 600px;
            position: relative;
        }
    }

    .textes-container {
        width:calc(100%);
        padding: 60px 10% 45px;
        @include brk($t) {
            width:calc(100%);
            padding: 55px 6% 45px;
        }
        @include brk(800px) {
            width:100%;
            padding: 79px 6% 40px;
        }
        .nom {
            font-size: 22.5px;
            font-weight: $bold;
            animation: fade 0.5s;
            line-height: 1.5;
            padding-bottom: 20px;
            text-transform: uppercase;
            border-bottom: 1px solid $touche1;
            margin-bottom: 24px;
            @include brk($m) { font-size: 23px; }
        }
        .texte p {
            font-size: 16px;
            line-height:1.70;
            animation: fade 1.5s;
            @include brk($m) {
                line-height:1.6;
            }
        }
        .cv {
            font-size: 16px;
            font-weight: $bold;
            margin-top: 20px;
            margin-bottom: 10px;
            position: relative;
            &::after {
                content:"";
                display: inline-block;
                background: $touche1;
                position: absolute;
                height:3px;
                left:10px;
                bottom:0px;
                width:0%;
                opacity: 0;
                transition: width 0.1s, opacity 0.3s;
                @include brk($td) {
                    color:#000;
                    width:100%;
                    opacity:1;
                }
            }
            &:hover::after {
                width:calc(100% - 10px);
                opacity:1;
            }
        }
        .bloc-liens {
            display: none!important;
            margin-top: 20px;
            animation: fade 2.5s;
            display: inline-block;
            width:100%;
            a {
                position: relative;
                display: inline-block;
                padding:0;
                margin:0;
                padding-right:20px;
                margin-right:10px;
                text-align: left;
                &::after {display:none;}
                &:not(.phone):hover  {
                    p { 
                        // color:$touche1;
                        &::after {width: 100%;opacity:0.2}
                    }
                    // .icon path {fill:$touche1;}
                }
            }
            .icon {
                width:30px;
                height:30px;
                position: absolute;
                top:10px;
                left:1px;

                path {fill:#b1b1b1;transition: fill 1s;}
            }
            p {
                text-align: left;
                display: inline-block;
                transition: color 0.2s;
                padding:10px 0px 10px 0px;
                margin-left: 46px;
                position: relative;
                &::after {
                    content: "";
                    display: inline-block;
                    position: absolute;
                    width: 0%;
                    left:0px;
                    bottom:8px;
                    height:2px;
                    opacity: 0;
                    transition: width 0.1s, opacity 0.5s;
                    background: #6ea03b;
                }
            }
        }
    }

    .publications-container {
        padding: 40px 0% 40px;
        background:#f8f5f4;
        .titre-section {
            font-size: 22.5px;
            color:$touche2;
            font-weight: $bold;
            padding:0 10%;
            margin-bottom: 20px;
            text-transform: uppercase;
            @include brk($t) {
                padding: 0px 6%;
            }
        }
        ul {
            li {
                &:first-child .titre-post {
                    border-top:none!important;
                }
                a {
                    display: inline-block;
                    width: 100%;
                    letter-spacing: 0.5px;
                    padding: 0px 10%;
                    @include brk($t) {
                        padding: 0px 6%;
                    }
                    .titre-post {
                        font-size: 12.5px;
                        font-weight: $normal;
                        transition: border 0.5s;
                        padding: 17px 0;
                        text-transform: uppercase;
                        border-top:1px solid transparentize(#000, 0.9);
                    }
                }
                &:hover {
                    a {background:#fff;}
                    & .titre-post, & + li .titre-post {
                        transition: border 0s;
                        border-top:1px solid transparentize(#000, 1);
                    }
                }
            }
        }
    }
    .close-btn {
        display: inline-block;
        width:60px;
        height:60px;
        position: absolute;
        z-index: 1100;
        top:-1px;
        right:-1px;
        cursor:pointer;
        background:$touche2;
        // animation: fade 0.5s;
        .icon {
            display: block;
            width:15px;
            height:15px;
            margin: 0 auto;
            margin-top: 22px;
            .st0 {
                stroke:#fff;
                fill:none;stroke-width:2.5;stroke-miterlimit:10;
            }
        }
        @include brk($m) {
            height:60px;
            width:60px;
        }
        &:hover {
            // transition: background 1.8s;
            background:$touche1;
            .st0{fill:none;stroke-width:4.5;stroke-miterlimit:10;}
        }
   }
    
}
