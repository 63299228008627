

.page-accueil {
    background: #fff;
}



// ACCUEIL HEIGHT BANNER TOP

$pgac_bT_h-desktop: 544px;
$pgac_bT_h-laptop: 520px;
$pgac_bT_h-tablet: 790px;
$pgac_bT_h-mobile: 492px;

.page-accueil .banner.top {
    background: $footer-color;
    height:$pgac_bT_h-desktop;
    transition: height 1s;
    @include brk ($l) { height:$pgac_bT_h-laptop; }
    @include brk ($t) { height:$pgac_bT_h-tablet; }
    @include brk ($m) { height:$pgac_bT_h-mobile; }
    .bloc-container {
        line-height: $pgac_bT_h-desktop;
        transition: line-height 1s;
        @include brk ($l) { line-height:$pgac_bT_h-laptop; }
        @include brk ($t) { line-height:359px; }
        @include brk ($m) { line-height:284px; }
    }
}






.page-accueil .banner.top {
    .image {
        background-position:50% 50%;
        @include brk($t) {
            position: relative;
            background-position:85% 50%;
        }
        @include brk($m) {
        }
    }
    .image-container {
        height:100%;
        @extend .abs;

        @include brk($t) {
            height:431px;
            position: relative;
            background-position:85% 50%;
        }
        @include brk($m) {
            height:208px;
        }
    }
    .banner-content {
        @include brk($t) {
            height:790px - 431px;
            position: relative;
            background:#fff;
        }
        @include brk($m) {
            height:492px - 208px;
        }
    }
    .bloc-container {
        display: none;
        .bloc {
            width:50%;
            text-align: left;
            padding:50px 0% 0;
            transition: padding-left 1s;
            @include brk($l + 170px) {
                padding:50px 0% 0 40px;
            }
            @include brk($l + 70px) {
                padding:50px 0% 0 90px;
            }
            @include brk($t) {
                width:100%;
                padding:0px 0% 0 0%;                
            }
            @include brk($m) {
                padding:0px 0% 0;
            }
            .textes {
                .sur-titre {
                    animation: fade 1.5s;
                    > p {
                        font-size: 11.5px;
                        font-weight: $semi-bold;
                        font-family: $family1;
                        animation: fade 1s;
                        color: transparentize($touche1, 0);
                        text-transform: uppercase;
                        letter-spacing: 1.5px;
                        line-height: 2;
                        padding: 0 0%;
                        user-select:none;
                        margin-bottom: 10px;
                        transform: translate3d(0,0,0); 
                        &.move_up { animation: move_up_15 1s; }
                        @include brk($t) {
                            padding: 0 0;
                        }
                        @include brk($m) {
                            font-size: 10.5px;
                            margin-bottom: 12px;
                            line-height: 1.5;
                            // display: none;
                        }
                    }
                }
                .titre {
                    animation: fade 1.5s;
                    > h2 {
                        font-size: 55px;
                        font-weight: $bold;
                        font-family: $family1;
                        clear: both;
                        color: transparentize(#343434, 0);
                        display: inline-block;
                        letter-spacing: 0.3px;
                        // text-transform: uppercase;
                        word-spacing: 2px;
                        margin-bottom: 0px;
                        position: relative;
                        line-height: 1.1;
                        user-select:none;
                        transform: translate3d(0,0,0); 
                        &.move_up { animation: move_up_10 1s; }
                        @include brk($t) {
                            font-size: 40px;
                        }
                        @include brk($m) {
                            font-size: 28px;
                            line-height: 1.1;
                            margin-bottom: 0px;
                        }
                        b, strong, span {
                            color:$touche1;
                            font-weight: $bold;
                        }

                        // // FLECHE ----------------------
                        // position: relative;
                        // &:before {
                        //     content:"›";
                        //     position: absolute;
                        //     top:-10px;
                        //     left:-46px;
                        //     font-size: 64px;
                        //     display: inline-block;
                        //     font-weight: $normal;
                        //     color:$touche1;
                        //     @include brk($t) {
                        //         display:none;
                        //     }
                        // }
                        
                        // // TRAIT ------------------------
                        // position: relative;
                        // &::after {
                        //     content:"";
                        //     position: absolute;
                        //     top:22px;
                        //     left:-83px;
                        //     height:1px;
                        //     width:54px;
                        //     opacity: 1;
                        //     transition: width 0.1s, opacity 0.5s;
                        //     display: inline-block;
                        //     background: transparentize(#fff, 0.7);
                        //     @include brk($t) {
                        //         display:none;
                        //     }
                        // }
                    }
                }
                .chapeau, .sous-titre {
                    animation: fade 2s;
                    p {                    
                        font-size: 42.5px;
                        font-weight: $light;
                        font-family: $family1;
                        color:$touche1;
                        line-height: 1.18;
                        user-select:none;
                        transform: translate3d(0,0,0); 
                        &.move_up { animation: move_up_5 1s; }
                        @include brk($t) {
                            font-size: 40px;
                        }
                        @include brk($m) {
                            font-size: 27.5px;
                        }
                    }
                }
                .lien {
                    margin-top: 40px;
                    height:60px;
                    transform: translate3d(0,0,0); 
                    background:none !important;
                    .inside {
                        animation: fade 2.5s;
                        background: $touche1;
                        border-radius:28px;
                    }
                    &.move_up { animation: move_up_5 1s; }
                    @include brk($t) {
                        margin-top: 30px;
                    }
                    @include brk($m) {
                        margin-top: 20px;
                    }
                    p {
                        font-size: 15.5px;
                        font-weight: $bold;
                        font-family: $family1;
                        color:#fff;
                        padding-top:18px;
                        // text-transform: uppercase;
                    }
                    &::after {
                        content:"";
                        position: absolute;
                        bottom:-6px;
                        left:0px;
                        height:3px;
                        width:0;
                        opacity: 0;
                        transition: width 0.1s, opacity 0.5s;
                        display: inline-block;
                        background: #fff;
                        @include brk($td) {
                            width:100%;
                            opacity: 1;
                        }
                    }
                    &:hover {
                        .inside {
                            background:$touche2;
                        }
                        &::after {
                            opacity: 1;
                            width:100%;
                        }
                    }
                }
            }
        }
    }
}





// SLIDER ------------------------------------------

.page-accueil #slider_home_top {
    height: 100%;
    
}






// SLIDER CONTROL PREV / NEXT  ----------------------------------

.slider-control-prev-next {
    height:100px;
    width:200px;
    position: absolute;
    left:calc(50% - 750px);
    bottom:40%;
    z-index: 10;
    animation: fade 2s;
    transform: rotate(90deg);
    display: none;
    @include brk($l + 70px) {
        left:calc(50% - 685px);
    }
    @include brk($t) {
        top:auto;
        bottom:170px;        
        left:calc(13% - 95px);
        transform: rotate(90deg);
    }
    @include brk($m) {
        right:-65px;
        top:102px;
        display: none;
    }
    .inside {
        .control {
            width:100px;
            height:100px;
            line-height: 100px;
            display: inline-block;
            text-align: center;
            &:hover svg {transform: scale(0.5)!important}
            .icon {
                @extend .bloc;
                height:40px;
                width:40px;
                svg {
                    transition: transform 0.3s;
                    transform: scale(0.42);
                    polygon { fill:$touche2; }
                }
            }
            &.prev {
                float:left; 
                padding-left:35px;
                .icon {transform: rotate(180deg)}
            }
            &.next {
                float:right;
                padding-right:35px;
            }
        }
    }
}








// SIDE BUTTONS ----------------------------------

.page-accueil .banner.top .side-btn {
    animation: fade 2s;
    position:absolute;
    height:100%;
    width:15%;
    z-index: 10;
    top:0;
    margin-top: ($header-height / 2);
    display:inline-block;
    cursor: pointer;
    // display: none;
    @include brk($t) {
        height:431px;
    }
    @include brk($m) {
        height:208px;
    }
    .icon {
        text-align: center;
        position: absolute;
        top:calc(50% - 91px);
        width:60px;
        height:60px;
        border-radius:30px;
        background:#fff;
        svg {
            transform:rotate(-90deg) scale(0.3);
            polygon {
                transition: fill 0.3s;
                fill:transparentize(#000, 0.5)}
        }
        @include brk($t) {
            width:52px;
            height:52px;
        }
        @include brk($m) {
            width:52px;
            height:52px;
        }
    }
    &.left {
        left:0px;
        .icon {
            left:35%; transform:rotate(180deg);
            transition: box-shadow 0.3s;
            box-shadow: -2px -2px 5px 0px rgba(0, 0, 0, 0.15);
        }
    }
    &.right {
        right:0px;
        .icon {
            right:35%;
            transition: box-shadow 0.3s;
            box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.15);
        }
    }
    &:hover {
        svg polygon {fill:transparentize($touche2, 0)}
    }
    &.left:hover .icon {
        box-shadow: -2px -2px 5px 0px rgba(0, 0, 0, 0.25);
    }
    &.right:hover .icon {
        box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.25);
    }
}








// VIGNETTES ------------------------------------------

.banner.vignettes {
    min-height: 850px;
    margin-top: -6px;
    padding-top:138px;
    padding-bottom:120px;
    background:#f9fafd;
    @include brk($t) {
        height:auto;
        padding-top:50px;
        padding-bottom:60px;
        min-height: 550px;
    }
    @include brk($m) {
        padding-bottom:0px;
        background: #eaf5f6;
    }
    >.image {
        @include brk($t) {
            background-position: 50% 0% !important;
        }
    }
    .bloc-titre-section {
        text-align: center;
        padding: 100px 0 120px;
        .titre-section {
            color:#003359;
            font-size: 19px;
            font-weight: $bold;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
        @include brk($t) {
            padding: 100px 0 50px;
        }
        @include brk($m) {
            padding: 35px 0 50px;
            .titre-section {
                font-size: 15px;
            }
        }
    }
    ul {
        text-align:center;
        position: relative;
        // animation: fade 1s;
    }
    li {
        width:33%;
        height:440px;
        z-index:10;
        display: inline-block;
        padding-left:3px;
        position: absolute;
        top:0;
        transition: left 1s, top 1s, transform 0.5s;

        @keyframes move_vignettes_up {
            0% { opacity: 0; transform:translate3d(0,30px,0) scale(0.95) }
            30% { opacity: 0.8; }
            100% { opacity: 1; transform:translate3d(0,0px,0) scale(1) }
        }
        @for $i from 1 through 10 {
            &:nth-child(#{$i}) { 
                animation-delay: ((0.05s * $i) - 0.05s);
                animation-duration: 0.5s + (0.16s * $i);
                animation-name: move_vignettes_up;
            }
        }

        &:nth-child(1) {left:0%;}
        &:nth-child(2) {left:33.33%;}
        &:nth-child(3) {left:66.66%;}
        @include brk($t) {
            position: relative;
            height:auto;
            width:calc(100%);
            left:0% !important;
            top:auto;
            text-align: left;
            // &:nth-child(1) {}
            // &:nth-child(2) {top:430px;}
            // &:nth-child(3) {top:860px;}
            // &:nth-child(1) {left:0%;}
            // &:nth-child(2) {left:50%;}
            // &:nth-child(3) {left:0%; top:430px;}
        }
        @include brk($m) {
            width: 100%;
            left:0% !important;
            padding-left:0px;
            text-align: center;
        }
        a {
            padding: 195px 15% 80px;
            position: relative;
            height:100%;
            transition: transform 0.5s;
            background-color: #fff;
            @include brk($t) {
                // background-color: transparentize(#fff,1);
                padding: 30px 15% 50px 200px;
            }
            @include brk($m) {
                padding: 148px 12% 45px;
            }
        }
        .image-container {
            width:110px;
            height:110px;
            position: absolute;
            transition: transform 0.5s;
            top:50px;
            left: calc(50% - 55px);
            animation: scale 1s;
            @include brk($t) {
                top:17px;
                left:7% !important;
                top:calc(50% - 50px);
            }
            @include brk($m) {
                width:100px;
                height:100px;
                top:30px;
                left: calc(50% - 50px) !important;
            }
            .image {
                border-radius:50%;
                overflow: hidden;
                width:100%;
                height:100%;
                animation:fade 1s;
                // animation: scale 2s;
            }
        }
        .bloc-titre {
            display: inline-block;
            .titre {
                font-size:22px;
                font-weight: $light;
                font-family: $family1;
                color:$touche1;
                position: relative;
                display: inline-block;
                padding-bottom: 20px;
                animation: fade 1s;
                @include brk($t) {
                    padding-top:10px;
                    padding-bottom: 10px;
                }
                @include brk($m) {
                    padding-top:0px;
                    padding-bottom: 5px;
                }
            }
            .trait {
                width:54px;
                height:3px;
                position: relative;
                margin: 0px auto 28px;
                transition: width 1s;
                animation: fade 1.5s;
                background: $touche1;
                // display: none;
            }
        }
        .texte {
            font-size:14px;
            position: relative;
            line-height: 1.6;
            color:#727272;
            font-weight: $light;
            letter-spacing: 0px;
            animation: fade 2s;
        }
        .btn-container {
            position: absolute;
            width:100%;
            left:0;
            bottom:40px;
            animation: fade 2.5s;
            display: none;
            button {
                background-color:#c8c8c8;
                padding:10px 20px;
                border-radius: 7px;
                display: inline-block;
                width:158px;
                transition: background 0.5s, width 0.4s, border-radius 0.4s;
                p {
                    color:#fff!important;
                    font-size:14px;
                    font-weight: $normal;
                }
                .icon {
                    position: absolute;
                    width:20px;
                    height:20px;
                    bottom:12px;
                    left:calc(50% - 60px);
                    .st0{fill:#FFFFFF;}
                }
            }
        }
        &:hover {

            a {
                // background:$touche1;
                transform: translate3d(0,5px,0);
                @include brk($t) {
                    transform: translate3d(0,0px,0);
                }
            }
            .titre, .texte {
                // color:#fff;
            }
            
            .image-container { transform: scale(1.05); }

            button {
                width:165px;
                border-radius:0px;
                background:#fff;
                .st0 {fill:inherit!important}
                p {color:inherit!important;}
            }
        }
        &:nth-child(1) {
            .ombre-top,
            .ombre.side.right { display: none!important; }
        }
        &:nth-child(2) {

        }
        &:nth-child(3) {
            .ombre.side.left {
                display: none;
            }
        }
        .ombre {
            position: absolute;
            animation: fade 3s;
            background-size:contain;
            &.side {
                background-position: 100% 0%;
                width:40px;
                height:100%;
                top:0;
                bottom:0;
                background-image:url('../assets/images/ombre-vignette-side.png');
                @include brk($t) {
                    display: none;
                }
                &.left {
                    left:-40px;
                }
                &.right {
                    right:-40px;
                    transform: rotate(180deg);
                }
            }
            &.bottom {
                background-position: 50% 0%;
                width:100%;
                left:0;
                bottom:-59px;
                height:60px;
                background-image:url('../assets/images/ombre-vignette-bottom.png');
            }
        }
        .ombre-top {
            position: absolute;
            animation: fade 3s;
            background-size:contain;
            background-position: 50% 100%;
            width:calc(100% - 200px);
            opacity: 0.7;
            height:30px;
            left: 200px;
            right:0;
            top:-35px;
            background-image:url('../assets/images/ombre-vignette-tablet.png');
        }
    }
}





.page-accueil .banner.about {
    padding-bottom:40px;
    @include brk($t) {
        padding-top: 80px;
        padding-bottom:55px;
    }
    @include brk($m) {
        padding-bottom:10px;
    }

    .content {
        margin-top: -200px;
        background:#fff;
        @include brk($t) {
            margin-top: 0px;
        }
        .banner-content {
            padding:0;
            max-width: 100%;
        }
    }
    
    .col-image {
        width:50%;
        height:500px;
        float: right;
        margin-bottom: 0;
        @include brk($td) {
            width:100%;
            height:445px;
        }
        @include brk($m) {
            height:181px;
        }
    }
    .col-texte {
        width:50%;
        float: left;
        padding-top:60px;
        padding-left: 65px;
        padding-right: 80px;
        @include brk($t) {
            padding-top: 0;
        }
        @include brk($td) {
            width:100%;
            padding-right: 0px;
            padding-top:50px;
            padding-left: 0px;
        }
        .sur-titre {
            display: inline-block;
            margin-bottom: 30px;
            // TRAIT ------------------------
            position: relative;
            &::after {
                content:"";
                position: absolute;
                top:5px;
                left:calc(100% + 15px);
                height:1px;
                width:74px;
                opacity: 1;
                transition: width 0.1s, opacity 0.5s;
                display: inline-block;
                background: transparentize(#000, 0.7);
            }
        }
        .titre {
            font-size: 37.5px;
            margin-bottom: 40px;
            @include brk($td) {
                margin-bottom: 20px;
            }
            @include brk($m) {
                margin-bottom: 5px;
                font-size: 27.5px;
            }
        }
        .texte {
            margin-bottom: 55px;
            p {
                line-height: 1.85;
                font-size: 14px;
            }
            @include brk($td) {
                margin-bottom: 30px;
            }
        }
        .lien {
            position: relative;
            display: block;
            padding-left: 30px;
            font-size: 17px;
            line-height: 1.5;
            margin-bottom: 13px;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
                &::before {
                    color:$touche2 !important;
                }
            }
            &::before {
                content: '›';
                color: $touche1;
                font-weight: $bold;
                position: absolute;
                left: 0px;
                top: 0px;
            }
        }
    }
}









.page-accueil .banner.list {
    background: #f9fafd;
}






.page-accueil .top-footer {
    min-height:100px;
    padding-top: 50px;
    @include brk($t) {
        border-bottom:1px solid transparentize(#000, 0.9);
    }
    @include brk($tp) {
        display: none;
    }
    .content {
        border-bottom:1px solid transparentize(#000, 0.9);
        padding-bottom:70px;
        @include brk($t) {
            border-bottom:none;
        }
    }
    .texte-container {
        width:calc(100% - 350px);
        p {
            padding-top: 10px;
            font-size: 25px;
            color:darken(#424242, 10%);
            letter-spacing: 0;
            position: relative;
            user-select:none;
            line-height: 1.5;
            font-weight: $thin;
        }
    }
    .btn-01 {
        height:60px;
        position: absolute;
        top:-1px;
        right:0;
        border-radius:30px;
        &:hover, &:focus {
            background:$touche2;
        }
        p {
            padding-top:18px;
            font-size: 15.5px;
            // text-transform: uppercase;
        }
        .icon {
            top:13px;
            padding-top:0;
        }
    }
}






