



.page {
    background:#fff;
}



// PAGE BANNER TOP

$page_wrapper-bannerTop-desktop: 286px;
$page_wrapper-bannerTop-laptop: 260px;
$page_wrapper-bannerTop-tablet: 260px;
$page_wrapper-bannerTop-mobile: 220px;



.page .banner.top {
    background:$footer-color;
    transition: height 1s;
    height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { height:$page_wrapper-bannerTop-mobile; }
}
.page .banner.top .bloc-container {
    transition: line-height 1.8s;
    line-height:$page_wrapper-bannerTop-desktop;
    @include brk($l) { line-height:$page_wrapper-bannerTop-laptop; }
    @include brk($t) { line-height:$page_wrapper-bannerTop-tablet; }
    @include brk($m) { line-height:$page_wrapper-bannerTop-mobile; }
}





.page .banner.top {
    position: relative;
    .image {
        background-position: 50% 50%;
        // filter: saturate(0%);
        &::after {
            @extend .overlay;
            content:"";
            background: linear-gradient(to right, #fff, transparentize(#fff, 0) 40%, transparentize(#fff, 1) 75%, transparentize(#fff, 1));
            @include brk($td) {
                background: transparentize(#000, 0.2);
            }
        }
    }
    .bloc {
        padding: 0px 0 0 0;
        @include brk($t) {
            padding: 10px 0 0 0;
            // text-align: center;
        }
    }
    .sur-titre {
        font-size: 11px;
        font-weight: $bold;
        font-family: $family1;
        color:$touche1;
        animation: fade 2.5s;
        text-transform: uppercase;
        display: inline-block;
        letter-spacing: 4px;
        position: relative;
        z-index: 4;
        user-select:none;
        margin-bottom: 10px;
        @include brk($t) { 
        }
        @include brk($m) { 
            font-size: 10px; 
            margin-bottom: 12px;
        }
         // TRAIT ------------------------
        &::after {
            content:"";
            position: absolute;
            top:7px;
            left:calc(100% + 11px);
            height:1px;
            width:54px;
            opacity: 1;
            transition: width 0.1s, opacity 0.5s;
            display: inline-block;
            background: transparentize(#000, 0.6);
            @include brk($o) {
                display:none;
            }
        }
    }
    .titre {
        font-size:52.5px;
        font-weight: $thin;
        font-family: $family1;
        color:#343434;
        animation: fade 2s;
        line-height: 1.1;
        letter-spacing: 0px;
        position: relative;
        // text-transform: uppercase;
        z-index: 4;
        user-select:none;
        @include brk($t) { font-size: 42px; }
        @include brk($td) { font-size: 40px; color:#fff }
        @include brk($m) { font-size: 30px;}

        // TRAIT ------------------------
        // &::after {
        //     content:"";
        //     position: absolute;
        //     top:22px;
        //     left:-83px;
        //     height:1px;
        //     width:54px;
        //     opacity: 1;
        //     transition: width 0.1s, opacity 0.5s;
        //     display: inline-block;
        //     background: transparentize(#fff, 0.6);
        //     @include brk($o) {
        //         display:none;
        //     }
        // }
    }
    .icon-page {
        position: absolute;
        width: 180px;
        height: 180px;
        bottom:-111px;
        right:0;
        z-index: 20;
        animation: fade 1s;
        .icon {
            background-size:contain;
        }
    }
    
}






.page.type {
    .banner.medium {
        background: #ffffff;
        min-height: 600px;
        padding:90px 0 0px;
        @include brk($t) {
            padding:50px 0 0px;
        }
        >.banner-content {
            // padding:0;
            // max-width: none;
        }
        .col-right {
            width:calc(100% - 345px);
            @include brk($t) {
                width:100%;
            }
        }
        .col-left {
            width:345px;
            @include brk($t) {
                width:100%;
            }
        }
    }
}













// BANNER FOOTER CALL TO ACTION   -----------------------------------------------------



.banner.footer-call-to-action {
    padding: 65px 0 60px;
    background:#212121;
    margin-top: -5px;
    animation: fade 2s !important;
    .fond {
        position: absolute;
        background-size: contain;
        height:100%;
        width:50%;
        background-position: 50% 100%;
        right:7%;
        bottom:0;
        background-image:url('../assets/images/fond-footer-call-to-action.png');
        @include brk($l) {
            width:60%;
            right:0%;
        }
        @include brk($t) {
            width:70%;
        }
        @include brk($tp) {
            width:100%;
        }
    }
    &::before {
        content:"";
        background:linear-gradient(to bottom, transparentize(#fff, 1) 0%, transparentize(#fff, 0.2) 50%, transparentize(#fff, 0) 100%);
        height:100px;
        width:calc(50% - 280px);
        position: absolute;
        top:-100px;
        left:0;
    }
    @include brk($t) {
        padding: 65px 0 75px;
    }
    .content {
        position: relative;
    }
    .titres-container{
        width: 30%;
        float: left;
        padding-right:6%;
        margin-bottom: 20px;
        @include brk($t) {
            width:100%;
            clear: both;
        }
        .titre {
            color:#fff;
            font-size: 25px;
            font-weight: $thin;
            margin-bottom: 25px;
        }
        .chapeau p {
            color:#ffffff;
            font-weight: $light;
            font-size: 14px;
            line-height: 1.8;
        }
    }
    .liste-container {
        width: 40%;
        float: left;
        @include brk($t) {
            width:100%;
            clear: both;
        }
        ul {
            margin-top: -13px;
            @include brk($t) {
                margin-top: -5px;
                margin-bottom: 10px;
            }
        }
        li{
            &:before {display:none}
            padding-left: 46px;
            position: relative;
            &:last-child p {border:none!important}
            .icon {
                position: absolute;
                top:20px;
                left:8px;
                height:17px;
                width:17px;
                // .st0{fill:#dd5953;stroke:#dd5953;stroke-width:7;stroke-linecap:square;}
            }
        }
        p {
            color:#fff;
            font-size: 16px;
            font-weight: $light;
            padding-top:17px;
            padding-bottom:17px;
            border-bottom:1px solid transparentize(#fff, 0.9);
        }
    }
    .btn-01 {
        background: #dd5953;
        border-radius:5px;
        position: absolute;
        right:0;
        @include brk($t) {
            position: relative;
            right:auto;
        }
        @include brk($td) {
            margin-top: 30px;
        }
        &:hover {
            background: darken(#dd5953, 15%);
        }
    }
}