

.lightbox-textesuite-all-container {
    display: none;
    &.active {
        display: block;
    }
}


$overlay_opacity: 0.75;

.page-overlay.textesuite {
    position: fixed;
    display: block;
    background: #000;
    opacity:$overlay_opacity;
    z-index:1999;
    &.ng-enter {
        opacity: 0;
        transition: opacity 0.3s;
        &.ng-enter-active { opacity:$overlay_opacity; }
    }
    &.ng-leave {
        opacity:$overlay_opacity;
        transition: opacity 0.35s;
        &.ng-leave-active { opacity: 0; }
    }
}



.lightbox-container.textesuite {
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    z-index:2000;
    overflow-y: scroll;
    width: calc(101.9%);
    cursor: pointer;
    @include brk($t) {
        width: calc(100% + 25px);
    }
}

.lightbox.textesuite {
    position: absolute;
    top:130px;
    left:50%;
    margin-left: -470px;
    z-index: 1000;
    display: inline-block;
    width:940px;
    overflow:hidden;
    transition: top 1s;
    cursor: default;
    border: 1px solid rgba(255,255,255,0.1);
    &.ng-enter {
        transition: transform 0.5s, opacity 0.3s; 
        transform:scale(0.85); opacity:0;
    }
    &.ng-enter-active {transform:scale(1); opacity:1}
    @include brk($t) {
        top:100px;
        width:80%;
        left: 10%;
        margin-left: 0;
    }
    @include brk($m) {
        top:100px;
        width:90%;
        left:5%;
        margin-left: 0;
        // position: absolute;
    }
    .inside {
        display: inline-block;
        padding: 80px 9% 80px;
        min-height:300px;
        margin-bottom: 120px;
        background:#fff;
        @include brk($m) {
            margin-bottom: 75px;
        }
    }
    .titre {
        font-size: 27px;
        line-height: 1.5;
        font-weight: $light;
        animation: fade 0.5s;
        @include brk($m) { font-size: 24px; }
    }
    .chapeau {
        // color:$touche_de_couleur;
        font-size: 14px;
        font-weight: $bold;
        padding-top:0;
        animation: fade 1s;
    }
    .texte {
        animation: fade 1s;
    }
    .close-btn {
        display: inline-block;
        width:60px;
        height:60px;
        position: absolute;
        z-index: 1100;
        top:-1px;
        right:-1px;
        cursor:pointer;
        background:#efefef;
        animation: fade 2.5s;
        .icon {
            display: block;
            width:15px;
            height:15px;
            margin: 0 auto;
            margin-top: 22px;
            .st0{fill:none;stroke-width:2.5;stroke-miterlimit:10;}
        }
        @include brk($m) {
            height:60px;
            width:60px;
        }
        &:hover {
            transition: background 1.8s;
            background:#fff;
            .st0{fill:none;stroke-width:4.5;stroke-miterlimit:10;}
        }
   }
    
}
