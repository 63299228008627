
$headroom_background_fade_out: 1s;
$headroom_background_opacity: 0.96;

$headroom_speed_in: 0.6s;
$headroom_speed_out: 0.8s;


$headroom_move-desktop: $header-height + 1px;



@keyframes slideUp_desktop {
    0% { transform: translateY(0); }
    100% { transform: translateY(- $headroom_move-desktop); }
}
@keyframes slideDown_desktop {
    0% { transform: translateY(- $headroom_move-desktop); }
    100% { transform: translateY(0); }
}
@keyframes slideUp_mobile {
    0% { transform: translateY(0); }
    100% { transform: translateY(- $header-height-mobile); }
}
@keyframes slideDown_mobile {
    0% { transform: translateY(- $header-height-mobile); }
    100% { transform: translateY(0); }
}




header#header>.background {
    background: #fff;
}


header#header.headroom {
    will-change: transform;
    animation-fill-mode: both;
    // animation-timing-function: ease-in-out;
    .background {
        transition: opacity $headroom_background_fade_out;
        opacity: $headroom_background_opacity;
    }
    &.headroom--top {
        .background {
            opacity: 0!important;
            transition: opacity $headroom_background_fade_out;
        }
    }
    &.headroom--pinned {
        animation-duration: $headroom_speed_in;
        animation-name: slideDown_desktop;
        @include brk($t) {
            animation-name: slideDown_mobile;
        }
        .background {
            transition: opacity $headroom_background_fade_out;
            opacity: $headroom_background_opacity;
        }
    }
    &.headroom--unpinned {
        animation-duration: $headroom_speed_out;
        animation-name: slideUp_desktop;
        transform: translateY(- $header-height);
        @include brk($t) {
            animation-name: slideUp_mobile;
        }
        .background {
            opacity: $headroom_background_opacity;
        }
    }
}



// #hamburger_container.headroom, 
#calling_button.headroom {
    will-change: transform;
    animation-fill-mode: both;
    &.headroom--top {
    }
    &.headroom--pinned {
        animation-duration: $headroom_speed_in;
        animation-name: slideDown_mobile;
    }
    &.headroom--unpinned {
        animation-duration: $headroom_speed_out;
        animation-name: slideUp_mobile;
    }
}







// NAV-SIDE ---------------------------------

.nav_side.headroom {
    will-change: position;
    &.headroom--not-top {
        position: fixed;
        top:200px;
    }
}