$ft: $t + 0px;
$fm: $m + 0px;



footer {
    overflow-y: hidden;
    background: $footer-color;
    // margin-top: -5px;
    z-index: 10;
    #footer_top {
        background:$footer-color;
        padding:60px 0 60px;
        // border-top: 1px solid transparentize(#000, 0.9);
        @include brk($ft) {
            padding:30px 0 30px;
        }
        @include brk($fm) {
            padding:54px 0 0px;
            border-top: 1px solid transparentize(#000, 0.9);
        }
    }
    #footer_bottom {
        padding: 0px 0 13px;
        .content {
            padding-top: 18px;
            border-top: 1px solid transparentize(#000, 0.9);
        }
        @include brk($fm) {
             padding: 18px 0 12px;
            .banner-content { padding:0; }
        }
    }
    .banner-content {
        @include brk($ft) { padding: 0 6%; }
        @include brk($fm) { padding: 0 6%; }
    }
    .titre-section {
        color:$touche1;
        font-size: 17.5px;
        font-weight: $bold;
        letter-spacing: 0.5px;
        margin-bottom: 15px;
        user-select:none;
        @include brk($fm) {
            // margin-bottom: 30px;
        }
    }
}




// LOGO ---------------------------------------

footer .logo-container {
    height:100px;
    width: 200px;
    float: left;
    @include brk($ft) {
        margin: 0 auto;
        float: none;
    }
    .logo-image {
        height:100%;
        width:100%;
        .st0{clip-path:url(#SVGID_2_);fill:#FFFFFF;}
        .st1{clip-path:url(#SVGID_2_);fill:#75B558;}
    }
}






// MENU ---------------------------------------------

footer .menu-footer {
    float: left;
    @include brk($fm) {
        margin-bottom: 35px;
    }
    .titre-section {
        span {
            color:$touche2;
        }
    }
    >ul>li {
        &:first-child {
            a { border-top:none; }
        }
        &:hover, &:focus {
            a { color:transparentize($touche1, 0); }
        }
    }
    >ul>li>a {
        font-size: 16px;
        font-weight: $normal;
        padding:6px 0px;
        width:200px;
        color:transparentize(#343434, 0.1);
        border-top: 1px solid transparentize(#343434, 1);
        &::before {
            content:"›";
            margin-right: 5px;
        }
    }
    >ul>li>ul {display:none;}
}






// COORDONNES -----------------------------------------

footer .bloc-coordonnees {
    width:25%;
    float: right;
    @include brk($ft) { width: 48%; float: left;}
    @include brk($fm) { width:100%; float: none; margin-bottom: 50px;}
    p, a {
        font-size: 15px;
        color:$touche1;
        line-height: 2;
        font-weight: $light;
        font-family: $family1;
        user-select: text;
        span {
            font-weight: $bold;
        }
        @include brk($fm) { 
            // font-size: 13.5px;
            letter-spacing: 0;
        }
    }
    span {
        display:block;
        color:$touche1;
        a {padding-left:5px;}
    }
    span[itemprop="address"] {
        margin-bottom: 25px;
        p {
            color:$touche2;
            font-size: 15px;
        }
    }
    a {position: relative;}
    a::after {
        content:"";
        display: inline-block;
        position: absolute;
        bottom:5px;
        left:0;
        height:2px;
        width:0;
        opacity: 0;
        transition: opacity 0.5s, width 0.2s;
        background: lighten($touche2, 30%);
    }
    a:hover, a:focus {
        color:$touche2 !important;
        -webkit-text-stroke: $touche2;
        text-decoration: underline;
        &::after {
            width:100%;
            // opacity: 1;
        }
    }
}








// SOCIAL ICONS  -----------------------------------------

footer .social-container {
    position: absolute;
    right:0;
    top:17px;
    margin-top: 12px;
    @include brk($ft) {
        display: none;
    }
    @include brk($fm) {
        // float:none;
        // text-align: center;
        // display: block;
        // margin: 0 auto 40px;
    }
    .icon-container {
        margin-left: 10px;
        &.xing {
            transform: translate3d(0,2px,0);
        }
        .icon {
            width:20px;
            height:20px;
            // background:#0d1221;
            // border-radius:50%;
        }
        .st0 {fill:#0d1221}
        .st1 {fill:$touche1; transition: fill 0.3s}
        &:hover, &:focus {
            .st0 {fill:$touche1}
            .st1 {fill:$touche1;}
        }
    }
}










// NEWSLETTER ----------------------------------


footer .bloc-newsletter {
    float: left;
    width: 350px;
    margin-right: 127px;
    @include brk($ft) {
        width:100%;
        margin-bottom: 60px;
    }
    @include brk($fm) {
        margin-bottom: 16px;
    }
    .titre-section {
        @include brk($ft) {
            display: inline-block;
            // float: left;
            margin-top: 26px;
        }
        @include brk($fm) {
            float: none;
             margin-top: 5px;
        }
    }
    .texte-newsletter {
        font-size: 14px;
        color:#6e6e6e;
        font-weight: $normal;
        letter-spacing: 0.5px;
        user-select:none;
        width: 286px;
        line-height: 2;
        margin-bottom: 25px;
        span {
            letter-spacing: 0.5px;
            color:$white;
            font-weight: $normal;
        }
        @include brk($ft) {
            width: 100%;
            margin-bottom: 22px;
        }
        @include brk($fm) {
        }
    }
    form {
        margin-top: 20px;
        position: relative;
        width:100%;
        border: 1px solid transparentize(#000, 0.85);
        height:65px;
        @include brk ($fm) {
            float: none;
            width:100%;
            margin: 5px 0 30px;
        }
    }

    $newsletter_input_padding_left: 20px;

    input {
        height:100%;
        padding:0px 20px 0px 20px;
        padding-left: $newsletter_input_padding_left;
        width:calc(100% - 73px);
        color:transparentize(#000, 0.1);
        float: left;
        font-weight: $normal;
        // border-radius:12px;
        font-size: 14px;
        letter-spacing: 1px;
        text-align: left;
        transition: border 0.5s;
        // border:1px solid rgba(255,255,255,0.2);
        &:focus {
            // border:1px solid rgba(255,255,255,0.3);
        }
        @include brk ($fm) {
        }
    }
    button {
        color:transparentize(#000, 0.3);
        // border:1px solid rgba(255,255,255,0.2);
        // border-radius:50%;
        width:54px;
        height:calc(100% - 12px);
        margin-top: 7px;
        margin-right: 5px;
        float: right;
        font-size: 13.5px;
        font-weight: $black;
        // background: $touche1;
        transition: color 0.1s, border 0.3s;
        letter-spacing: 1.5px;
        position: relative;
        &::before {
            content:"";
            display: inline-block;
            height:30px;
            width:1px;
            position: absolute;
            top:10px;
            left:-3px;
            background: transparentize(#000, 0.8);
        }
        &:hover {
            color:$touche1;
            // background: darken($touche1, 15%);
        }
        @include brk ($fm) {
        }
    }


    .response_box {
        float: left;
        clear: both;
        font-size: 12px;
        color:rgba(255,255,255,1);
        font-weight: $light;
        font-style: italic;
        margin-top:12px;
        width:calc(100% - 73px);
        text-align: left;
        padding-left: $newsletter_input_padding_left;
        @include brk($ft) {margin-top:8px;}
        &.success {color:green;}
        &.error {color:red;}
        display: none;
    }

        
    // FIX FOR CHROME AUTOFILL
    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0px 1000px #2a2a2a inset;
        -webkit-text-fill-color: transparentize(#fff,0.2) !important;
        border-bottom:1px solid #2a2a2a;
        &:focus {border-bottom:1px solid #143467!important;}
    }




    /**************  PLACEHOLDER ADJUST   ***************/
    input::placeholder, textarea::placeholder {
        font-size: 14px;
        font-weight: $normal;
        color: transparentize(#000, 0.5) !important;
        text-align: left;
        transition: opacity 0.3s;
        @include brk($ft){ font-size:14px; }
        @include brk($fm){ font-size:14px; }
    }
    input:focus, textarea:focus {
        &::placeholder { opacity: 0.25; }
    }

}
















// COPYRIGHT LINE ----------------------------------

footer .copyright-line {
    display:inline-block;
    text-align:center;
    margin-left: -7px;
    width:100%;
    @include brk($fm) {
        width:100%;
        text-align: center;
        margin-left: 0px;
    }
    p, a, span {
        font-size: 9.5px;
        font-weight: $normal;
        color:#343434;
        text-transform: uppercase;
        letter-spacing:0px;
        transition: color 0.6s;
    }
    a {
        padding: 15px 5px;
        text-decoration: none;
        position: relative;
        cursor:pointer;
        @include brk($fm) {
            padding: 15px 5px;
        }
        // &:hover, &:focus {color:$touche1;}
        &:first-child {
            border-bottom:none;
            &:before {display:none !important;}
        }
        &:nth-last-child(1):before {
            @include brk($fm) {
                // display: none;
            }
        }
        &::before {
            content:"";
            display: inline-block;
            position: absolute;
            top:17px;
            left:-3px;
            width:1px;
            height:11px;
            background:rgba(0,0,0,0.35);
            @include brk($fm){
                background:rgba(0,0,0,0.15);
            }
        }
        &.contact {
            @include brk($ft) {
                display: none;
            }
        }
        &.by {
            display: none;
        }
    }
    p {
        display:inline;
    }
}




