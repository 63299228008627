
.archive.references .selectize-angular-container {
    position: absolute;
    top:50px;
    width:310px;
    right:0;
    z-index: 200;
    box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.25);
    border-radius: 35px;
    @include brk($t) { 
        top:40px; 
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
    }
    @include brk($tp) { 
        top:29px; 
    }
    @include brk($m) { 
        width:100%;
        top:0;
        position: relative;
        margin-top: -10px;
        margin-bottom: 50px;
    }
    .selectize-control {
        background: none;
        text-align: left;
        height:60px;
        .item, .option {
            font-size: 14px;
            font-weight: $normal;
            font-family: $family1;
            letter-spacing: 0px;
            color:$touche2;
            padding-top:15px;
            line-height: 1.25;
            @include brk($m) { 
                font-size: 13.5px;
            }
        }
        .option {
            font-size: 13.5px;
            color:lighten($touche2, 2%);
            padding: 11px 20px;
            display: inline-block;
            width:100%;
            margin-top: -4px;
            // &:first-child {padding-top:16px}
            // &:last-child {padding-bottom:20px}
            &.selected, &.active {
                background: darken($touche1, 0%);
                color:#fff;
            }
        }
        .selectize-input {
            background: #fff;
            width:100%;
            height:60px;
            padding:0 20px;
            transition: border 0.5s, box-shadow 0.15s;
            border:1px solid transparentize(#fff, 0.8);
            border-radius:30px;
            cursor:pointer;
            position: relative;
            .item {
                padding-top:20px;
            }
            &.focus, &:focus {
                // border:1px solid transparentize($touche_de_couleur2, 0.8);
                box-shadow: none!important;
            }
            &::after {
                content:"";
                position: absolute;
                height:20px;
                width:20px;
                top:21px;
                right:21px;
                margin-top: 0;
                background:url('../assets/images/select-dropdown.svg');
            }
        }
        .selectize-dropdown {
            background: #fff;
            overflow: hidden;
            border-radius:30px;
            margin-top: 0px;
            border:none;
            box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.25);
            cursor:pointer;
            @include brk($t) {
                box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.25);
            }
            .selectize-dropdown-content { 
                padding: 0; 
                margin: 10px 0;
                position: relative;
                max-height: 200px;
                @include brk($m) { 
                    max-height: none;
                }
            }
        }
    }
}






